var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "qrcode-wrapper single-article" }, [
    _c("div", { staticClass: "qrcode-container", class: _vm.qrcodeStyle }, [
      _c(
        "div",
        { staticClass: "qrcode-text-describe fw700 flex-row-center " },
        [
          _c("img", {
            staticClass: "img-gray small-img",
            attrs: { src: require("@/assets/scan.png"), alt: "" }
          }),
          _vm._v(" 扫码申请进入协同审图 ")
        ]
      ),
      _c("div", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.qrLoading,
            expression: "qrLoading"
          }
        ],
        ref: "qrcode",
        attrs: { id: "qrcode" }
      })
    ]),
    _c(
      "div",
      { staticClass: "qr-hint-wrapper", on: { click: _vm.switchQrcodeStatus } },
      [
        _c("div", { staticClass: "qrcode-hint   fw700" }, [
          _c("img", {
            staticClass: "img-gray small-img",
            attrs: { src: require("@/assets/qrcode.png"), alt: "" }
          }),
          _c("span", { staticClass: "hint-text fw700" }, [
            _vm._v("邀请审图人员")
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }