import _objectSpread from "F:/@company/lzz-productcheck-front/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import manageInnerService from "@/service/manageInner-service";
import CheckQR from '@/components/CheckQR.vue';
import { mapState } from "vuex";
import store from '@/store';
export default {
  name: 'ManageInner',
  components: {
    CheckQR: CheckQR
  },
  data: function data() {
    return {
      diaMode: 'add',
      authDiaShow: false,
      loading: false,
      userLoading: false,
      authUserList: [],
      formData: {
        userCondition: ''
      },
      authForm: {
        department: "甲方",
        orgId: "",
        userName: '',
        roleCode: '',
        roleName: '',
        oldRoleName: '',
        roleCodeOld: '',
        userId: '',
        realName: ''
      },
      mainUserList: [],
      normalUserList: [],
      tagList: [],
      editUser: {},
      prjId: ""
    };
  },
  computed: _objectSpread({}, mapState({
    COEprjID: function COEprjID(state) {
      return state.prj.prjId;
    },
    prjBuId: function prjBuId(state) {
      return state.prj.prjBuId;
    }
  })),
  watch: {
    COEprjID: function COEprjID() {
      this.prjId = this.COEprjID || '';
      this.init();
    }
  },
  created: function created() {
    this.prjId = this.COEprjID || '';
    this.init(); // 需要加项目id
    // if (!!this.$route.query.prjID){
    //   this.prjId = this.$route.query.prjID || ''
    //   this.init()
    // }else {
    //   this.$message('缺少项目id...');
    // }
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      store.dispatch('onLoading', false);
    });
  },
  methods: {
    // 初始化判断prjId是否存在
    init: function init() {
      this.getMemberList();
      this.getTagList();
      this.authForm.prjId = this.prjId;
    },
    // 编辑 --普通人员表格
    editRole: function editRole(item) {
      this.editUser = item;
      this.authForm.userName = item.realName;
      this.authForm.oldRoleName = item.roleName;
      this.diaMode = 'edit';
      this.authDiaShow = true;
    },
    // 获取角色类型数据
    getTagList: function getTagList() {
      var _this = this;

      var roleName = '';
      manageInnerService.verifygetTagsList(roleName).then(function (res) {
        var data = [];

        if (res.result === '0') {
          // data = res.data
          for (var i = 0; i < res.data.length; i++) {
            res.data[i].leaf = false;

            if (res.data[i].isOuter === '0') {
              data.push(res.data[i]);
            }
          }
        }

        _this.tagList = data; // console.log(this.tagList,'11');
      });
    },
    // 点击 角色授权按钮
    roleAuth: function roleAuth() {
      this.diaMode = 'add';
      this.authDiaShow = true;
    },
    // 获取表格数据
    getMemberList: function getMemberList() {
      var _this2 = this;

      this.formData.prjId = this.prjId;
      manageInnerService.innerroleinnerRoleUsers(this.formData).then(function (res) {
        _this2.mainUserList = [];
        _this2.normalUserList = [];

        if (res.result === '0' && res.data) {
          _this2.mainUserList = res.data.ucRoleUsers;

          if (res.data.commonRoleUsers && res.data.commonRoleUsers.length) {
            for (var i = 0; i < res.data.commonRoleUsers.length; i++) {
              res.data.commonRoleUsers[i].roleCodeOld = res.data.commonRoleUsers[i].roleCode;
              res.data.commonRoleUsers[i].prjId = _this2.prjId;
            }
          }

          _this2.normalUserList = res.data.commonRoleUsers;
        }
      });
    },
    // 点击取消按钮 取消添加角色
    cancelHandle: function cancelHandle() {
      this.authDiaShow = false;
    },
    // 点击别的地方 取消添加角色
    closeHandle: function closeHandle() {
      this.authUserList = [];
      this.authForm.userName = '';
      this.authForm.roleCode = '';
    },
    // 点击搜索按钮
    search: function search() {
      this.getMemberList();
    },
    // 确定保存角色按钮 添加或者修改
    saveHandle: function saveHandle() {
      var _this3 = this;

      this.$refs.authForm.validate(function (valid) {
        if (valid) {
          if (_this3.diaMode === 'add') {
            var params = {
              department: "甲方",
              orgId: _this3.prjId,
              prjId: _this3.prjId,
              realName: _this3.authForm.realName,
              roleCode: _this3.authForm.roleCode,
              roleCodeOld: '',
              roleName: _this3.authForm.roleName,
              userId: _this3.authForm.userId,
              userName: _this3.authForm.userName
            };
            manageInnerService.innerUsercreate(params).then(function (res) {
              if (res.result === '0') {
                _this3.authDiaShow = false;

                _this3.$message.success('授权成功！');

                _this3.getMemberList();
              }
            });
          } else {
            var params1 = {
              department: _this3.editUser.department,
              orgId: _this3.editUser.orgId,
              prjId: _this3.editUser.prjId,
              realName: _this3.editUser.realName,
              roleCode: _this3.authForm.roleCode,
              roleCodeOld: _this3.editUser.roleCodeOld,
              roleName: _this3.authForm.roleName,
              userId: _this3.editUser.userId,
              userName: _this3.editUser.userName
            };
            manageInnerService.innerUserupdateUser(params1).then(function (res) {
              if (res.result === '0') {
                _this3.authDiaShow = false;

                _this3.$message.success('修改成功！');

                _this3.getMemberList();
              }
            });
          }
        }
      });
    },
    // 添加角色时 人员搜索
    getUserList: function getUserList(query) {
      var _this4 = this;

      if (query.trim()) {
        this.userLoading = true;
        manageInnerService.cityAdmingetAllUserList(query, this.prjId).then(function (res) {
          _this4.userLoading = false;
          _this4.authUserList = res.data;
        });
      } else {
        this.authUserList = [];
      }
    },
    // 重置
    resetData: function resetData() {
      this.$refs.form.resetFields();
      this.getMemberList();
    },
    // 删除人员 --普通人员
    deleteRole: function deleteRole(item) {
      var _this5 = this;

      this.$confirm('删除选中数据将不可撤销，是否继续操作?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        manageInnerService.innerUserdeleteUser(item.userId, item.prjId, item.roleCode).then(function (res) {
          if (res.result === '0') {
            _this5.getMemberList();

            _this5.$message.success('已成功删除！');
          }
        });
      }).catch(function () {});
    },
    // 打开添加或者编辑角色弹框
    openHandle: function openHandle() {
      this.$refs.authForm && this.$refs.authForm.clearValidate();
    },
    changeroleName: function changeroleName(val) {
      var _this6 = this;

      this.tagList.filter(function (itemx) {
        if (val == itemx.roleId) {
          _this6.authForm.roleName = itemx.roleName;
        }
      });
    },
    changerealName: function changerealName(val) {
      var _this7 = this;

      this.authUserList.filter(function (itemx) {
        if (val == itemx.userName) {
          _this7.authForm.userId = itemx.userId;
          _this7.authForm.realName = itemx.realName;
        }
      });
    }
  }
};