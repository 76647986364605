var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage-inner" },
    [
      _c(
        "div",
        { staticClass: "inner-header header-search" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.formData, "label-width": "80px" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名:", prop: "userCondition" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入姓名" },
                            model: {
                              value: _vm.formData.userCondition,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "userCondition", $$v)
                              },
                              expression: "formData.userCondition"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "40px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "info", size: "small" },
                              on: { click: _vm.resetData }
                            },
                            [_vm._v("重置")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.search }
                            },
                            [_vm._v("搜索")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { padding: "20px  0 0px 37px" } },
        [
          _vm.prjBuId != "X1"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.roleAuth.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(" 角色授权 ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "inner-section" }, [
        _c(
          "div",
          { staticClass: "section-mainUser" },
          [
            _c("p", { staticClass: "section-title" }, [_vm._v("项目7+1团队")]),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                attrs: { data: _vm.mainUserList }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "姓名（+OA账号）", "class-name": "firstCol" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            _vm._s(scope.row.realName) +
                              "（" +
                              _vm._s(scope.row.userName) +
                              "）"
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "roleName", label: "角色" }
                }),
                _c("el-table-column", { attrs: { label: "" } })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c("p", { staticClass: "section-title" }, [_vm._v("普通人员")]),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                attrs: { data: _vm.normalUserList, "reserve-selection": true }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "姓名（+OA账号）", "class-name": "firstCol" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            _vm._s(scope.row.realName) +
                              "（" +
                              _vm._s(scope.row.userName) +
                              "）"
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "roleName", label: "角色" }
                }),
                _c("el-table-column", {
                  attrs: { label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.prjBuId != "X1"
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editRole(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("编辑")]
                              )
                            : _vm._e(),
                          _vm.prjBuId != "X1"
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteRole(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "manage-authDia",
          attrs: {
            "modal-append-to-body": false,
            title: "角色授权",
            visible: _vm.authDiaShow,
            width: "408px"
          },
          on: {
            "update:visible": function($event) {
              _vm.authDiaShow = $event
            },
            closed: _vm.closeHandle,
            open: _vm.openHandle
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "authForm",
              attrs: {
                model: _vm.authForm,
                "label-width": "80px",
                "label-position": "top"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "请输入人员名称",
                    prop: "userName",
                    rules: [{ required: true, message: "请输入姓名" }]
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "380px" },
                      attrs: {
                        disabled: _vm.diaMode === "edit",
                        filterable: "",
                        remote: "",
                        placeholder: "请输入姓名",
                        "remote-method": _vm.getUserList,
                        loading: _vm.userLoading
                      },
                      on: { change: _vm.changerealName },
                      model: {
                        value: _vm.authForm.userName,
                        callback: function($$v) {
                          _vm.$set(_vm.authForm, "userName", $$v)
                        },
                        expression: "authForm.userName"
                      }
                    },
                    _vm._l(_vm.authUserList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.userName,
                          attrs: { label: item.realName, value: item.userName }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(item.realName) +
                                "（" +
                                _vm._s(item.userName) +
                                "）" +
                                _vm._s(item.orgName ? "-" + item.orgName : "")
                            )
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "请选择角色",
                    prop: "roleCode",
                    rules: [{ required: true, message: "请选择角色类型" }]
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "380px" },
                      attrs: {
                        filterable: "",
                        placeholder:
                          _vm.diaMode === "add"
                            ? "请选择角色类型"
                            : _vm.authForm.oldRoleName
                      },
                      on: { change: _vm.changeroleName },
                      model: {
                        value: _vm.authForm.roleCode,
                        callback: function($$v) {
                          _vm.$set(_vm.authForm, "roleCode", $$v)
                        },
                        expression: "authForm.roleCode"
                      }
                    },
                    _vm._l(_vm.tagList, function(item) {
                      return _c("el-option", {
                        key: item.roleId,
                        attrs: { label: item.roleName, value: item.roleId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { "text-align": "right" } },
                [
                  _c("el-button", { on: { click: _vm.cancelHandle } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveHandle }
                    },
                    [_vm._v("保 存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "fix-qrcode" }, [_c("CheckQR")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }