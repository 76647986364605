import _objectSpread from "F:/@company/lzz-productcheck-front/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import QRCode from 'qrcodejs2';
import inviteService from '@/service/invite-service';
import { mapState } from "vuex";
export default {
  name: 'CheckQR',
  data: function data() {
    return {
      qrLoading: false,
      qrCodeStatus: false
    };
  },
  computed: _objectSpread({
    qrcodeStyle: function qrcodeStyle() {
      if (!this.qrCodeStatus) return 'qrcode--hidden';else return 'qrcode--show';
    }
  }, mapState({
    COEprjID: function COEprjID(state) {
      return state.prj.prjId;
    },
    prjBuId: function prjBuId(state) {
      return state.prj.prjBuId;
    }
  })),
  watch: {
    COEprjID: function COEprjID() {
      this.loadingQrcode();
    }
  },
  created: function created() {
    this.loadingQrcode();
  },
  methods: {
    loadingQrcode: function loadingQrcode() {
      var _this = this;

      this.qrLoading = true;

      if (this.$refs.qrcode) {
        this.$refs.qrcode.innerHTML = '';
      }

      this.getSourceUrlInfo().then(function (res) {
        _this.qrcode(res.data);

        _this.qrLoading = false;
      });
    },
    switchQrcodeStatus: function switchQrcodeStatus() {
      this.qrCodeStatus = !this.qrCodeStatus;
    },
    qrcode: function qrcode(encodeText) {
      encodeText = encodeURIComponent(encodeURIComponent(encodeText));
      var url = "https://".concat(window.location.host, "/#/inviteCheck?encodeText=").concat(encodeText);
      console.log(url);
      var qrcode = new QRCode('qrcode', {
        width: 198,
        height: 198,
        text: url,
        colorDark: '#000',
        colorLight: '#fff'
      });
    },
    getSourceUrlInfo: function getSourceUrlInfo() {
      if (!this.COEprjID) return Promise.reject('当前无法获取到项目信息,在项目信息获取后将自动获取');
      return inviteService.getEncodeQRCodeInfo(this.COEprjID);
    }
  }
};