import request from '@/utils/request';
export default {
  verifygetTagsList: function verifygetTagsList(roleName) {
    return request.get('/app/v1/innerUser/getRolesList?roleName=' + roleName).then(function (res) {
      return res;
    });
  },
  innerroleinnerRoleUsers: function innerroleinnerRoleUsers(params) {
    return request.get('/app/v1/innerUser/list?prjId=' + params.prjId + '&userCondition=' + params.userCondition).then(function (res) {
      return res;
    });
  },
  cityAdmingetAllUserList: function cityAdmingetAllUserList(userName, prjId) {
    return request.get('/app/v1/innerUser/getAllUserList?userName=' + userName + '&prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  innerUsercreate: function innerUsercreate(params) {
    return request.post('/app/v1/innerUser/create', params).then(function (res) {
      return res;
    });
  },
  innerUserupdateUser: function innerUserupdateUser(params) {
    return request.post('/app/v1/innerUser/updateUser', params).then(function (res) {
      return res;
    });
  },
  innerUserdeleteUser: function innerUserdeleteUser(userId, prjId, roleCode) {
    return request.get('/app/v1/innerUser/deleteUser?userId=' + userId + '&prjId=' + prjId + '&roleCode=' + roleCode).then(function (res) {
      return res;
    });
  },
  outerUserupdateUser: function outerUserupdateUser(params) {
    return request.post('/app/v1/outerUser/updateUser', params).then(function (res) {
      return res;
    });
  },
  verifygetRolesList: function verifygetRolesList(params) {
    return request.post('/app-doc-center/v1/api/halo/verify/getRolesList', params).then(function (res) {
      return res;
    });
  },
  outerRoleUsers: function outerRoleUsers(params) {
    return request.get('/app/v1/outerUser/list?orgCondition=' + params.orgCondition + '&prjId=' + params.prjId + '&roleCondition=' + params.roleCondition + '&userCondition=' + params.userCondition).then(function (res) {
      return res;
    });
  },
  getByOrgName: function getByOrgName(orgName) {
    return request.get('/app/v1/outerUser/getOrgList?orgName=' + orgName).then(function (res) {
      return res;
    });
  },
  outerUserdeleteUser: function outerUserdeleteUser(userId, prjId, roleCode) {
    return request.get('/app/v1/outerUser/deleteUser?userId=' + userId + '&prjId=' + prjId + '&roleCode=' + roleCode).then(function (res) {
      return res;
    });
  }
};