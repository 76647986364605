import request from '@/utils/request';
var INVITE_BASE_URL = '/app/v1/invite';
export default {
  /**
   * 获得加密后时效性二维码元信息
   */
  getEncodeQRCodeInfo: function getEncodeQRCodeInfo(prjId) {
    return request.get("".concat(INVITE_BASE_URL, "/encodeQrcode?prjId=").concat(prjId));
  },

  /**
   * 解密加密信息
   */
  getDecodeQrCodeInfo: function getDecodeQrCodeInfo(encodeText) {
    return request.get("".concat(INVITE_BASE_URL, "/decodeQrcode?encodeText=").concat(encodeText));
  },

  /**
   * 接受邀请
   */
  confirmJoin: function confirmJoin(prjId) {
    return request.get("".concat(INVITE_BASE_URL, "/inviteJoinIn?prjId=").concat(prjId));
  }
};